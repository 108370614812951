<template>
    <b-row>
        <b-col cols="8">
            <b-tabs>
                <b-tab
title="Triggers"
active
lazy
>
                    <trigger />
                </b-tab>
                <b-tab
title="Custom"
lazy
>
                    <custom />
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>
<script>
import {
 BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue';
import trigger from './components/trigger.vue';
import custom from './components/custom.vue';
export default {
    components: {
        trigger,
        BTabs,
        BTab,
        BRow,
        BCol,
        custom,
    },
};
</script>
