<template>
    <div>
        <b-card no-body>
            <b-tabs
pills
card
vertical
>
                <b-tab
                    v-for="(trigger, key) in triggers"
                    :key="key"
                    :active="key === 0"
                >
                    <template #title>
                        {{ trigger.name }}
                        <b-spinner
                            v-if="
                                trigger.notification_templates.length > 0 &&
                                    trigger.active
                            "
                            type="grow"
                            small
                            class="ml-1"
                            variant="success"
                        />
                        <b-spinner
                            v-else
                            type="border"
                            small
                            class="ml-1"
                            variant="warning"
                        />
                    </template>
                    <b-card-text>
                        <b-card
                            :title="trigger.name"
                            :sub-title="trigger.description"
                        >
                            <b-row>
                                <b-form>
                                    <b-col>
                                        <b-form-group
                                            label="Notification Template"
                                            :label-for="
                                                `template_${trigger.id}`
                                            "
                                        >
                                            <b-form-select
                                                :id="`template_${trigger.id}`"
                                                v-model="
                                                    trigger.notification_template_id
                                                "
                                                text-field="name"
                                                value-field="id"
                                                :options="templates"
                                                placeholder="Select Notification Template"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group
                                            label="Status"
                                            :label-for="`active_${trigger.id}`"
                                        >
                                            <b-form-checkbox
                                                :id="`active_${trigger.id}`"
                                                v-model="trigger.active"
                                                switch
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-button
                                        variant="primary"
                                        @click.prevent="saveTrigger(trigger)"
                                    >
                                        Submit
                                    </b-button>
                                </b-form>
                            </b-row>
                        </b-card>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>
import {
    BCard,
    BButton,
    BCollapse,
    BCardBody,
    BCardText,
    BTabs,
    BTab,
    BSpinner,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        BCard,
        BCardText,
        BTabs,
        BTab,
        BSpinner,
        BForm,
        BFormGroup,
        BFormSelect,
        BFormCheckbox,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            triggers: [],
            templates: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.$helper.showLoading();
            this.$axios
                .get(`/notifications/list`)
                .then(resp => {
                    if (resp.status === 200 && resp.data.status) {
                        this.triggers = resp.data.triggers;
                        this.templates = resp.data.templates;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            this.$helper.hideLoading();
        },
        saveTrigger(trigger) {
            let { active } = trigger;
            if (typeof active === 'boolean') {
                active = active ? 1 : 0;
            }
            this.$helper.showLoading();
            this.$axios
                .post(`/notifications/save`, {
                    type: 'trigger',
                    id: trigger.id,
                    notification_template_id: trigger.notification_template_id,
                    active,
                })
                .then(resp => {
                    if (resp.status === 200 && resp.data.status) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Trigger Updated Successfully',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });
                        this.getData();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                })
                .finally(() => {
                    this.$helper.hideLoading();
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.green {
    color: green;
    font-weight: 500;
}
.red {
    color: red;
}
</style>
