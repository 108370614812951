<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-text>
                        <h4>Select Membership Levels</h4>
                        <custom-auto-populate
                            module="membershiplevels"
                            label="membership_level"
                            retrieve="object"
                            @changed="membershipLevelSelected"
                        />
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-text>
                        <h4>Selected Membership Levels</h4>
                        <b-list-group>
                            <b-list-group-item
                                v-for="item in selectedMembershipLevels"
                                :key="item.id"
                                class="d-flex justify-content-between align-items-center"
                            >
                                {{ item.title }}
                                <b-badge
                                    variant="danger"
                                    pill
                                    class="pointer"
                                    @click="
                                        removeSelectedMembershipLevel(item.id)
                                    "
                                >
                                    -
                                </b-badge>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-text>
                        <h4>Select Members</h4>
                        <custom-auto-populate
                            module="members"
                            label="email"
                            retrieve="object"
                            @changed="membersSelected"
                        />
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col>
                <b-card>
                    <b-card-text>
                        <h4>Selected Members</h4>
                        <b-list-group>
                            <b-list-group-item
                                v-for="item in selectedMembers"
                                :key="item.id"
                                class="d-flex justify-content-between align-items-center"
                            >
                                {{ item.title }}
                                <b-badge
                                    variant="danger"
                                    pill
                                    class="pointer"
                                    @click="removeSelectedMembers(item.id)"
                                >
                                    -
                                </b-badge>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-text>
                        <h4>Selected Destination</h4>
                        <b-form-select
                            v-model="destination"
                            :options="destinationOptions"
                        />
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-form-group label="Create Template">
                        <b-form-select
                            v-model="currentVariable"
                            text-field="name"
                            value-field="id"
                            :options="variables"
                            placeholder="Choose Variable"
                            class="mb-2"
                            @input="variableSelected"
                        />
                        <b-form-textarea
                            id="template"
                            ref="templateArea"
                            v-model="template"
                            placeholder="Template"
                            required
                            name="template"
                            :state="validateTemplate"
                        />
                        <b-form-invalid-feedback
                            v-show="!validateTemplate"
                            id="template-feedback"
                        >
                            Template is Required
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-button
            variant="primary"
            :disabled="submitDisabled"
            @click="onSubmit"
        >
            Submit
        </b-button>
    </div>
</template>
<script>
import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CustomAutoPopulate from '../../common/components/CustomAutopopulate.vue';
export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BListGroup,
        BListGroupItem,
        CustomAutoPopulate,
        BBadge,
        BFormGroup,
        BFormSelect,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
    },
    data() {
        return {
            membershipLevels: [],
            selectedMembershipLevels: [],
            members: [],
            selectedMembers: [],
            template: '',
            currentVariable: null,
            variables: [
                { id: null, name: 'Choose Variables from here' },
                { id: '{$member_first_name}', name: 'First Name of Member' },
                { id: '{$member_last_name}', name: 'Last Name of Member' },
            ],
            destination: '',
            destinationOptions: [
                { value: '', text: 'Please select an option' },
                { value: 'dashboard', text: 'Home View' },
                { value: 'experiences', text: 'Experiences View' },
                { value: 'profile', text: 'My Profile View' },
            ],
        };
    },
    computed: {
        validateTemplate() {
            return this.template.length > 0;
        },
        submitDisabled() {
            return !this.validateTemplate;
        },
    },
    methods: {
        membershipLevelSelected(val) {
            if (
                val
                && !this.selectedMembershipLevels.find(item => item.id === val.id)
            ) {
                this.selectedMembershipLevels.push({
                    id: val.id,
                    title: val.membership_level,
                });
            }
        },
        membersSelected(val) {
            if (val && !this.selectedMembers.find(item => item.id === val.id)) {
                this.selectedMembers.push({
                    id: val.id,
                    title: val.email,
                });
            }
        },

        removeSelectedMembershipLevel(rowId) {
            this.selectedMembershipLevels = [
                ...this.$helper.removeByAttr(
                    this.selectedMembershipLevels,
                    'id',
                    rowId,
                ),
            ];
        },
        removeSelectedMembers(rowId) {
            this.selectedMembers = [
                ...this.$helper.removeByAttr(this.selectedMembers, 'id', rowId),
            ];
        },
        variableSelected(value) {
            const textarea = this.$refs.templateArea;
            const cursorPosition = textarea.selectionStart;
            this.template = this.template.substr(0, cursorPosition)
                + value
                + this.template.substr(cursorPosition, this.template.length);
            textarea.focus();
        },
        onSubmit() {
            if (
                this.selectedMembershipLevels.length === 0
                && this.selectedMembers.length === 0
            ) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Target Audience is not set.',
                        icon: 'AlertTriangleIcon',
                        variant: 'warning',
                    },
                });
                return;
            }
            this.save();
        },
        save() {
            const memberIds = this.selectedMembers.map(item => item.id);
            const membershipLevelIds = this.selectedMembershipLevels.map(
                item => item.id,
            );
            this.$helper.showLoading();
            this.$axios
                .post(`/notifications/save`, {
                    type: 'custom',
                    member_ids: memberIds,
                    membship_level_ids: membershipLevelIds,
                    template: this.template,
                    destination: this.destination,
                })
                .then(resp => {
                    if (resp.status === 200 && resp.data.status) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: resp.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                })
                .finally(() => {
                    this.$helper.hideLoading();
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.pointer {
    cursor: pointer;
}
</style>
